import request from './request';
import { getAuth, setAuth, removeAuth, isOpenApi, getApiUrl } from './utils';

const API_URL = getApiUrl();

export const fetchAndSaveAuthToken = async ({ liffId, userId }) => {
  console.log('debug: fetchAndSaveAuthToken - ', { liffId, userId });
  if (isOpenApi()) {
    removeAuth();
    const url = `${API_URL}/v1/auth/lineUserIDAuth`;
    return request(url, {
      method: 'POST',
      headers: {
        'x-liff-id': liffId,
        'x-lineuser-id': userId,
      },
    }).then((res) => res.accessToken);
  } else {
    return request(`${API_URL}/api/token`, {
      method: 'GET',
      headers: {
        'X-liff-Id': liffId,
        'x-lineuser-id': userId,
      },
      withAuth: false,
    }).then((res) => {
      return res.token;
    });
  }
};

export const fetchOpenApiAuthToken = async ({ liffId, userId }) => {
  const url = `${process.env.OPEN_API_URL}/v1/auth/lineUserIDAuth`;
  return request(url, {
    method: 'POST',
    headers: {
      'x-liff-id': liffId,
      'x-lineuser-id': userId,
    },
  }).then((res) => res.accessToken);
};

export const reFetchAuthToken = async () => {
  const { 'x-liff-id': liffId, 'x-lineuser-id': userId } = getAuth();
  const newToken = await fetchAndSaveAuthToken({ liffId, userId });
  setAuth({ token: newToken, liffId, userId });
  return new Promise((resolve) => resolve(newToken));
};

export const getUserInfo = () => {
  const url = isOpenApi()
    ? `${API_URL}/api/internal/user`
    : `${API_URL}/api/user`;

  return request(url, {
    method: 'GET',
  });
};

export const getPasscode = ({ phone }) => {
  const url = isOpenApi()
    ? `${API_URL}/api/internal/phone/passcode`
    : `${API_URL}/api/phone/passcode`;
  return request(url, {
    method: 'POST',
    payload: { phone },
  });
};

export const validatePasscode = ({
  phone: rawPhone,
  passcode,
  lineVerify = false,
}) => {
  const url = isOpenApi()
    ? `${API_URL}/api/internal/phone/valid`
    : `${API_URL}/api/phone/valid`;
  const phone = rawPhone.replace(/\+886/g, '0');

  return request(url, {
    method: 'POST',
    payload: {
      phone,
      passcode,
      lineVerify,
    },
  });
};

export const createSelf = ({ name, idNum, birth, idType, gender }) => {
  const url = isOpenApi()
    ? `${API_URL}/api/internal/self`
    : `${API_URL}/api/self`;

  return request(url, {
    method: 'POST',
    payload: {
      name,
      idType,
      gender,
      IdNum: idNum,
      Birth: birth,
    },
  });
};

export const updatePatient = ({
  id,
  name,
  birth,
  idNum,
  idType,
  gender,
  note,
  relation: alias,
}) => {
  const url = isOpenApi()
    ? `${API_URL}/api/internal/patient/${id}`
    : `${API_URL}/api/patient/${id}`;

  const payload = { name, birth, idType, gender };
  if (alias) {
    payload.alias = alias;
  }
  if (idNum) {
    payload.idNum = idNum;
  }
  if (note) {
    payload.note = note;
  }
  return request(url, {
    method: 'PUT',
    payload,
  });
};

export const listMembers = async () => {
  try {
    const user = await getUserInfo();
    return {
      bindingPhoneNumber: user.Phone,
      members: user.patients
        ? user.patients
            .filter((p) => !p.isSelf)
            .map((p) => {
              const { id, name, idNum, birth, alias: relation } = p;
              return {
                ...p,
                id: `${id}`,
                name,
                idNum,
                birth,
                relation,
              };
            })
        : [],
    };
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const createMember = ({
  name,
  relation,
  idNum,
  idType,
  gender,
  birth,
}) => {
  const url = isOpenApi()
    ? `${API_URL}/api/internal/patient`
    : `${API_URL}/api/patient`;

  return request(url, {
    method: 'POST',
    payload: {
      name,
      idType,
      IdNum: idNum,
      Birth: birth,
      alias: relation,
      gender,
    },
  });
};

export const deleteMember = ({ id }) => {
  const url = isOpenApi()
    ? `${API_URL}/api/internal/patient/${id}`
    : `${API_URL}/api/patient/${id}`;

  return request(url, {
    method: 'DELETE',
  });
};
